<template>
  <section id="category-statistics">
    <b-card>
      <b-row>
        <b-col xl="2" md="2">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr v-model="dateRange" placeholder="Date Range" class="form-control" :config="{ mode: 'range' }"
              @on-change="changeDateRange" />
          </b-input-group>
        </b-col>
        <b-col xl="10" md="10">
          <v-select v-model="selectedCategories" placeholder="Categories" label="category" multiple
            :options="allCategories" @input="changeSelectedCategories" />
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="showCategories" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Category statistics
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-category-statistics" />
          </h4>
          <b-popover target="popover-category-statistics" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-overlay :show="showExportStart" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
              <feather-icon icon="DownloadIcon" size="21" class="text-muted cursor-pointer" @click="exportTable" />
            </b-overlay>
          </b-card-text>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="categoriesTable.currentPage"
          :items="categoriesTable.items" :fields="categoriesTable.fields" :sort-by.sync="categoriesTable.sortBy"
          :sort-desc.sync="categoriesTable.sortDesc" :sort-direction="categoriesTable.sortDirection"
          :filter="categoriesTable.filter" :filter-included-fields="categoriesTable.filterOn"
          @sort-changed="categoriesTableSortChanged" @row-clicked="categoryRowClicked" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="categoriesTable.currentPage" :total-rows="categoriesTable.totalRows" first-number
              last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleCategoriesTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-modal ref="category-details-modal" size="xl" :title="categoryName" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true" @hidden="resetCategoryDetailsModal"
      @shown="onCategoryDetailsModalShown">
      <b-overlay :show="showProductsByCategory" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
        <b-card no-body>
          <b-card-header>
            <h4 class="mb-0">
              Products by category
              <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                id="popover-products-category" />
            </h4>
            <b-popover target="popover-products-category" triggers="hover" placement="bottom">
              <span>No data</span>
            </b-popover>
          </b-card-header>
          <b-table striped hover responsive class="position-relative"
            :current-page="productsByCategoryTable.currentPage" :items="productsByCategoryTable.items"
            :fields="productsByCategoryTable.fields" :sort-by.sync="productsByCategoryTable.sortBy"
            :sort-desc.sync="productsByCategoryTable.sortDesc" :sort-direction="productsByCategoryTable.sortDirection"
            :filter="productsByCategoryTable.filter" :filter-included-fields="productsByCategoryTable.filterOn"
            @sort-changed="productsByCategoryTableSortChanged" />
          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- pagination -->
            <div>
              <b-pagination v-model="productsByCategoryTable.currentPage"
                :total-rows="productsByCategoryTable.totalRows" first-number last-number prev-class="prev-item"
                next-class="next-item" class="mb-0" @change="handleProductsByCategoryTablePageChange">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card>
      </b-overlay>
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Turnover over years
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-turnover-years" />
              </h4>
              <b-popover target="popover-turnover-years" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="categoryTurnoverByYearChart" :options="categoryTurnoverByYearChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Offers count over years
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-offers-count-years" />
              </h4>
              <b-popover target="popover-offers-count-years" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="categoryOffersByYearChart" :options="categoryOffersByYearChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BPagination,
  BCardBody,
  BCardText,
  BCardHeader,
  BPopover,
  BCard,
  BOverlay,
  BModal,
  BSpinner,
  BBadge,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import axios from 'axios';
import * as XLSX from 'xlsx';
import ECharts from 'vue-echarts';

import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';

const currentDate = new Date();
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BCard,
    BCardBody,
    BCardText,
    BCardHeader,
    BPopover,
    BOverlay,
    BModal,
    BSpinner,
    BBadge,
    BInputGroup,
    BInputGroupPrepend,
    vSelect,
    flatPickr,
    ECharts,
  },
  data() {
    return {
      showCategories: true,
      showCategoryDetails: true,
      showProductsByCategory: true,
      showCategoryTurnoverByYear: true,
      showCategoryOffersByYear: true,
      showExportStart: false,
      oldDateRange: `${currentDate.getFullYear()}-01-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      dateRange: `${currentDate.getFullYear()}-01-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      selectedCategories: [],
      allCategories: [],
      queryParamsCategories: {},
      queryParamsProductsByCategory: {},
      queryParamsCategoryDetails: {},
      categoryID: '',
      categoryName: '',
      categoriesTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'turnover',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'category', label: 'category', sortable: true },
          { key: 'qty_sold', label: 'items sold', sortable: true },
          { key: 'qty_sold_prev_year', label: 'items sold prev. year', sortable: true },
          { key: 'diff_qty_sold', label: 'diff. items sold', sortable: true },
          {
            key: 'diff_qty_sold_percent',
            label: 'diff. items sold %',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatNumber(val)} %`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'turnover',
            label: 'turnover',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'turnover_prev_year',
            label: 'turnover prev. year',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'diff_turnover',
            label: 'diff. turnover',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'diff_turnover_percent',
            label: 'diff. turnover %',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatNumber(val)} %`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'margin',
            label: 'margin',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'margin_p',
            label: 'margin %',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatNumber(val)} %`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'share_total_sales',
            label: 'Share of total sales %',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatNumber(val)} %`;
              } else {
                return '';
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      productsByCategoryTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'turnover',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          { key: 'qty', label: 'qty', sortable: true },
          {
            key: 'turnover',
            label: 'turnover',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      categoryTurnoverByYearChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      categoryOffersByYearChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
    }
  },
  async created() {
    const dateRange = this.dateRange.split(' to ');

    const from_date_str = dateRange[0];
    const to_date_str = dateRange[1];
    const from_date = new Date(from_date_str);
    const to_date = new Date(to_date_str);

    const from_date_new = from_date;
    from_date_new.setFullYear(from_date_new.getFullYear() - 1);
    const from_date_prev_year = new Date(from_date_new);
    const to_date_new = to_date;
    to_date_new.setFullYear(to_date_new.getFullYear() - 1);
    const to_date_prev_year = new Date(to_date_new);

    this.queryParamsCategories.from_date = from_date_str;
    this.queryParamsCategories.to_date = to_date_str;
    this.queryParamsCategories.from_date_prev_year = `${from_date_prev_year.getFullYear()}-${formatMonth(from_date_prev_year)}-${formatDate(from_date_prev_year)}`;
    this.queryParamsCategories.to_date_prev_year = `${to_date_prev_year.getFullYear()}-${formatMonth(to_date_prev_year)}-${formatDate(to_date_prev_year)}`;

    this.queryParamsProductsByCategory.from_date = from_date_str;
    this.queryParamsProductsByCategory.to_date = to_date_str;

    try {
      await this.getAllCategories();
      await this.getCategoryRating();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getAllCategories() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-categories/`, {});
        this.allCategories = response.data.results;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getCategoryRating() {
      this.showCategories = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/category-rating/`, this.queryParamsCategories);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.categoriesTable.items = results;
          this.categoriesTable.totalRows = results[0].count * 2;
        }
        else {
          this.categoriesTable.items = [];
          this.categoriesTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showCategories = false;
      }
    },
    async getProductsByCategory() {
      this.showProductsByCategory = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/products-by-category/`, this.queryParamsProductsByCategory);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.productsByCategoryTable.items = results;
          this.productsByCategoryTable.totalRows = results[0].count * 2;
        }
        else {
          this.productsByCategoryTable.items = [];
          this.productsByCategoryTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showProductsByCategory = false;
      }
    },
    async getCategoryTurnoverByYear() {
      this.showCategoryTurnoverByYear = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/category-turnover-by-year/`, this.queryParamsCategoryDetails);
        const results = response.data.results;
        this.categoryTurnoverByYearChart.xAxis.data = results.map(item => item.year);
        this.categoryTurnoverByYearChart.series[0] = {
          name: 'Turnover YTD',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? `${params.value.toLocaleString('de-DE')} YTD` : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: results.map(item => item.turnover_ytd),
        };
        this.categoryTurnoverByYearChart.series[1] = {
          name: 'Turnover',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: results.map(item => item.turnover),
        };
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showCategoryTurnoverByYear = false;
      }
    },
    async getCategoryOffersByYear() {
      this.showCategoryOffersByYear = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/category-offers-by-year/`, this.queryParamsCategoryDetails);
        const results = response.data.results;
        this.categoryOffersByYearChart.xAxis.data = results.map(item => item.year);
        this.categoryOffersByYearChart.series = {
          name: 'Count',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: results.map(item => item.offers_count),
        };
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showCategoryOffersByYear = false;
      }
    },
    async changeSelectedCategories() {
      this.queryParamsCategories.category_ids = this.selectedCategories.map(item => item.category_id);
      await this.getCategoryRating();
    },
    async categoriesTableSortChanged(value) {
      if (value.sortDesc === true) {
        this.queryParamsCategories.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsCategories.ordering = value.sortBy;
      }
      await this.getCategoryRating();
    },
    async productsByCategoryTableSortChanged(value) {
      if (value.sortDesc === true) {
        this.queryParamsProductsByCategory.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsProductsByCategory.ordering = value.sortBy;
      }
      await this.getProductsByCategory();
    },
    async handleCategoriesTablePageChange(value) {
      this.queryParamsCategories.page = value;
      await this.getCategoryRating();
    },
    async handleProductsByCategoryTablePageChange(value) {
      this.queryParamsProductsByCategory.page = value;
      await this.getProductsByCategory();
    },
    async changeDateRange() {
      const dateRange = this.dateRange.split(' to ');
      if (dateRange.length > 1 && this.dateRange != this.oldDateRange) {
        const from_date_str = dateRange[0];
        const to_date_str = dateRange[1];
        const from_date = new Date(from_date_str);
        const to_date = new Date(to_date_str);

        const from_date_new = from_date;
        from_date_new.setFullYear(from_date_new.getFullYear() - 1);
        const from_date_prev_year = new Date(from_date_new);
        const to_date_new = to_date;
        to_date_new.setFullYear(to_date_new.getFullYear() - 1);
        const to_date_prev_year = new Date(to_date_new);

        this.queryParamsCategories.from_date = from_date_str;
        this.queryParamsCategories.to_date = to_date_str;
        this.queryParamsCategories.from_date_prev_year = `${from_date_prev_year.getFullYear()}-${formatMonth(from_date_prev_year)}-${formatDate(from_date_prev_year)}`;
        this.queryParamsCategories.to_date_prev_year = `${to_date_prev_year.getFullYear()}-${formatMonth(to_date_prev_year)}-${formatDate(to_date_prev_year)}`;

        this.queryParamsProductsByCategory.from_date = from_date_str;
        this.queryParamsProductsByCategory.to_date = to_date_str;

        await this.getCategoryRating();
        this.oldDateRange = this.dateRange;
      }
    },
    categoryRowClicked(row) {
      this.categoryID = row.category_id;
      this.categoryName = row.category;

      this.queryParamsProductsByCategory.category_id = this.categoryID;
      this.queryParamsCategoryDetails.category_id = this.categoryID;

      this.getProductsByCategory();
      this.getCategoryTurnoverByYear();
      this.getCategoryOffersByYear();

      this.$refs['category-details-modal'].show();
    },
    resetCategoryDetailsModal() {
      this.categoryID = '';
      this.categoryName = '';
    },
    onCategoryDetailsModalShown() {
      this.$nextTick(() => {
        this.$refs.categoryTurnoverByYearChart.resize();
        this.$refs.categoryOffersByYearChart.resize();
      });
    },
    async exportTable() {
      try {
        this.showExportStart = true;
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-category-rating/`, this.queryParamsCategories);
        const exportedData = response.data.results;
        this.showExportStart = false;
        // Convert the data to a worksheet
        let worksheet = XLSX.utils.json_to_sheet(exportedData);
        // Create a new workbook, and add the worksheet to it
        let workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        // Export the workbook to an xlsx file
        XLSX.writeFile(workbook, 'CategoryStatistics.xlsx');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>